import React from 'react'

import { Box, Flex, GatsbyImage, GatsbyImageContainer } from 'system'
import { ButtonArrow } from 'src/components/ButtonArrow'
import { Heading } from 'src/components/Heading'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { TapaPatternFill } from 'src/components/TapaPatternFill'

export const FeaturedPage = ({
  to,
  title,
  descriptionMarkdown,
  imageFluid,
  imageSide = 'left',
  ...props
}) => {
  return (
    <Box as="section" mx={[2, 4]} mt={[2, 4]} mb={[2, 4]} {...props}>
      <Flex flexDirection={['column', 'row']} height={[null, '24rem']}>
        <Flex
          alignItems="center"
          bg="black"
          justifyContent={imageSide === 'left' ? 'flex-end' : 'flex-start'}
          minHeight={['10rem', 0]}
          order={[null, imageSide === 'left' ? 1 : 2]}
          p={[4, 6]}
          position="relative"
          width={[1, 1 / 2]}
        >
          {imageFluid && (
            <GatsbyImageContainer
              height="100%"
              left={0}
              position="absolute"
              top={0}
              width="100%"
              opacity={title ? 0.4 : 1}
            >
              <GatsbyImage
                fluid={imageFluid}
                alt={title}
                css={`
                  pointer-events: none;
                `}
              />
            </GatsbyImageContainer>
          )}
          {title && (
            <Heading
              color="yellow"
              maxWidth="calc(80rem / 2 - 3rem)"
              position="relative"
            >
              {title}
            </Heading>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems={imageSide === 'left' ? 'flex-start' : 'flex-end'}
          bg="teal"
          order={[null, imageSide === 'left' ? 2 : 1]}
          p={[4, 6]}
          width={[1, 1 / 2]}
          position="relative"
        >
          <TapaPatternFill />
          <Box width={1} maxWidth="calc(80rem / 2 - 3rem)">
            <MarkdownContent
              markdown={descriptionMarkdown}
              map={{
                h1: props => <Heading color="yellow" mb={2} {...props} />,
              }}
              color="white"
              lineHeight="copy"
              fontSize={['medium', 'midLarge']}
              position="relative"
            />
            {to && (
              <ButtonArrow to={to} mt={[2, 4]} color="yellow">
                Learn More
              </ButtonArrow>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
