import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, size } from 'lodash/fp'
import { mapIndexed } from 'helpers'

import { Layout } from 'src/components/Layout'

import { ButtonList } from 'src/slices/ButtonList'
import { DetailedPageIntro } from 'src/slices/DetailedPageIntro'
import { FeaturedPage } from 'src/slices/FeaturedPage'

const ServicesPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const featuredServices = get('frontmatter.featuredServices', page)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <DetailedPageIntro
        pageTitle={get('frontmatter.title', page)}
        markdown={get('frontmatter.description', page)}
        imageFluid={get('frontmatter.image.childImageSharp.fluid', page)}
      />
      {mapIndexed(
        ([i, service]) => (
          <FeaturedPage
            key={get('title', service)}
            descriptionMarkdown={get('description', service)}
            imageSide={i % 2 ? 'right' : 'left'}
            imageFluid={get('image.childImageSharp.fluid', service)}
            title={get('title', service)}
            to={`/services/${get('serviceRelation', service)}/`}
            mb={i >= size(featuredServices) - 1 ? 0 : undefined}
          />
        ),
        featuredServices
      )}
      <ButtonList heading="More Services">
        {map(
          service => (
            <ButtonList.Button
              key={get('title', service)}
              to={`/services/${get('serviceRelation', service)}/`}
            >
              {get('title', service)}
            </ButtonList.Button>
          ),
          get('frontmatter.otherServices', page)
        )}
      </ButtonList>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPage {
    file(relativePath: { eq: "customPages/services.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          featuredServices: featured_services {
            serviceRelation: service_relation
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          otherServices: other_services {
            serviceRelation: service_relation
            title
          }
        }
      }
    }
  }
`
