import React from 'react'

import { Flex, Text, Button as ButtonBase, Link, SVG } from 'system'
import { IconTriangleRight } from 'src/components/IconTriangleRight'

export const ButtonLarge = ({ children, ...props }) => (
  <ButtonBase as={Link} bg="sandLight" display="block" p={3} {...props}>
    <Flex alignItems="center">
      <Text
        as="span"
        color="teal"
        flex="1 1 auto"
        fontFamily="sansCond"
        fontSize={['medium', 'midLarge']}
        fontWeight="bold"
        lineHeight="title"
        mr={3}
        textStyle="smallCaps"
      >
        {children}
      </Text>
      <SVG
        svg={IconTriangleRight}
        x={1}
        y={2}
        fill="yellow"
        width="0.75rem"
        flex="0 0 auto"
      />
    </Flex>
  </ButtonBase>
)
