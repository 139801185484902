import React from 'react'

import { Box } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { ButtonLarge } from 'src/components/ButtonLarge'
import { Heading } from 'src/components/Heading'

const Button = ({ children, to, target, ...props }) => (
  <Box
    as="li"
    mb={[2, 3]}
    boxStyle="lastNoMargin"
    css={`
      break-inside: avoid;
    `}
    {...props}
  >
    <ButtonLarge to={to} target={target}>
      {children}
    </ButtonLarge>
  </Box>
)

export const ButtonList = ({ heading, children, ...props }) => (
  <BoundedBox as="section" color="teal" {...props}>
    {heading && (
      <Heading mb={4} textAlign="center">
        {heading}
      </Heading>
    )}
    <Box
      as="ul"
      columns={[null, 2]}
      mb={[-2, -3]}
      css={`
        column-gap: 2rem;
      `}
    >
      {children}
    </Box>
  </BoundedBox>
)

ButtonList.Button = Button
